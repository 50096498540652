import React from 'react';
import useResizeObserver from 'use-resize-observer';

import classes from './Footer.module.css';
import Copyright from '../Copyright/Copyright';
import { FooterLinkProps } from '../FooterLink/FooterLink';
import FooterLinkList from '../FooterLinksList/FooterLinkList';

const Footer = (): React.ReactElement => {
  const links: FooterLinkProps[] = [
    {
      to: '/about',
      text: 'About',
      internal: true
    },
    {
      to: 'https://twitter.com/Boluptuous',
      text: 'Twitter',
      internal: false
    },
    {
      to: 'https://github.com/boingram',
      text: 'GitHub',
      internal: false
    },
    {
      to: 'https://www.linkedin.com/in/bo-ingram-1a069275',
      text: 'LinkedIn',
      internal: false
    }
  ];

  const [ref, width] = useResizeObserver();

  let components = [<Copyright key="copyright" />, <FooterLinkList key="footerLinks" footerLinks={links} />];

  if (width < 600) {
    components = [<FooterLinkList key="footerLinks" footerLinks={links} />, <Copyright key="copyright" />];
  }

  return (
    <footer className={classes.footer} ref={ref}>
      <div className={classes.footerContainer}>{components}</div>
    </footer>
  );
};

export default Footer;
