import React from 'react';
import { Link } from 'gatsby';

export interface FooterLinkProps {
  to: string;
  text: string;
  internal: boolean;
}

const FooterLink = (props: FooterLinkProps): React.ReactElement => {
  const { internal, to, text } = props;

  return internal ? <Link to={to}>{text}</Link> : <a href={to}>{text}</a>;
};

export default FooterLink;
