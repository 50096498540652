import React from 'react';
import { Link } from 'gatsby';

import classes from './Header.module.css';

export interface HeaderProps {
  siteTitle: string;
}

const Header = ({ siteTitle }: HeaderProps): React.ReactElement => {
  return (
    <header className={classes.header}>
      <Link to="/">{siteTitle}</Link>
    </header>
  );
};

export default Header;
