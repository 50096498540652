import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import classes from './Layout.module.css';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';

export interface LayoutProps {
  children: React.ReactChild | React.ReactChild[];
}

const Layout = ({ children }: LayoutProps): React.ReactElement => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className={classes.layout}>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main className={classes.contentContainer}>
        <div className={classes.content}>{children}</div>
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
