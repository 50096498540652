/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import favicon16 from '../../images/favicon-16x16.png';
import favicon32 from '../../images/favicon-32x32.png';
import favicon64 from '../../images/favicon-64x64.png';

interface Meta {
  name?: undefined;
  content: any;
  property: string;
}

export interface SEOProps {
  description?: string;
  lang?: string;
  meta?: Meta[];
  title: string;
}

function SEO({ description = '', lang = 'en', meta = [], title }: SEOProps): React.ReactElement {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ].concat(meta)}
      link={[
        { rel: 'icon', type: 'image/png', sizes: '16x16', href: `${favicon16}` },
        { rel: 'icon', type: 'image/png', sizes: '32x32', href: `${favicon32}` },
        { rel: 'shortcut icon', type: 'image/png', href: `${favicon64}` },
        { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Bungee+Shade|Open+Sans&display=swap' }
      ]}
      script={[{ src: 'https://platform.twitter.com/widgets.js', async: true }]}
    />
  );
}

export default SEO;
