import React from 'react';

import classes from './FooterLinkList.module.css';
import FooterLink, { FooterLinkProps } from '../FooterLink/FooterLink';

export interface FooterLinkListProps {
  footerLinks: FooterLinkProps[];
}

const FooterLinkList = (props: FooterLinkListProps): React.ReactElement => {
  const { footerLinks } = props;

  const footerLinkComponents: React.ReactElement = footerLinks
    .map(
      (link: FooterLinkProps): React.ReactElement => {
        return <FooterLink key={link.to} to={link.to} text={link.text} internal={link.internal} />;
      }
    )
    .reduce(
      /* eslint react/no-array-index-key: 0 */
      (prev: React.ReactElement, curr: React.ReactElement, i: number): any => [prev, <span key={i}> | </span>, curr]
    );

  return <div className={classes.footerLinkList}>{footerLinkComponents}</div>;
};

export default FooterLinkList;
